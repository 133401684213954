import React from "react";
import styles from "./FieldGroup.module.scss"
import {injectIntl} from "react-intl";

const Field = ({label, labelClassName, value, fieldRowClassName}) => {
    return (
        <div className={[styles.fieldRow, fieldRowClassName].join(" ")}>
            <div className={[styles.label, labelClassName].join(" ")}>
                {label}
            </div>
            <div>
                {value}
            </div>
        </div>
    );
};

const FieldGroup = ({fields, children, intl, className, internationaliseValues = false}) => (
    <div className={[styles.group, className].join(" ")}>
        {fields && fields.map(({label, value}) => <Field key={label} label={intl.formatMessage({id: label})}
                                                         value={internationaliseValues ? intl.formatMessage({id: value}) : value}/>)}
        {children}
    </div>
);

FieldGroup.Field = Field;

export default injectIntl(FieldGroup);
