import axios from "axios";

export const getCards = (billerId, setCards) => {
    axios.get(`/data/cards/${billerId}`)
        .then(({data}) => setCards(data.cards));
};

const DEFAULT_CARD_NUMBER_PADDING = "**** **** **** ";

const CARD_NUMBER_PADDING = {
    amex: "**** ****** *"
};

export const buildMaskedNumber = (issuer, last4Digits) => {
    const cardNumberPadding = CARD_NUMBER_PADDING[issuer] || DEFAULT_CARD_NUMBER_PADDING;
    return cardNumberPadding + last4Digits;
};

export const expireMonth = expireMonth => {
    if (("" + expireMonth).length === 1) {
        return "0" + expireMonth;
    }
    return expireMonth;
};