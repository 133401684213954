import React from "react";
import {injectIntl} from "react-intl";
import {Table, SecondaryButton} from "../../common";
import {CSVLink} from "react-csv";

function getChannel(intl, channel){
    switch (channel){
        case 'bpv':
            return intl.formatMessage({id:"registrations.channel.bpv"});
        case 'epost':
            return intl.formatMessage({id:"registrations.channel.epost"});
        case 'email':
            return intl.formatMessage({id:"registrations.channel.email"});
        case 'xeroconnect':
            return intl.formatMessage({id:"registrations.channel.xeroconnect"});
        case 'myob':
            return intl.formatMessage({id: "registrations.channel.myob"});
        case 'mybillsagent':
            return intl.formatMessage({id:"registrations.channel.mybillsagent"});
        case 'mybills':
            return intl.formatMessage({id:"registrations.channel.mybills"});
        case 'reckon':
            return intl.formatMessage({id:"registrations.channel.reckon"});
        case 'einvoicing':
            return intl.formatMessage({id:"registrations.channel.einvoicing"});
        case 'archive':
            return intl.formatMessage({id:"registrations.channel.archive"});
        default:
            return intl.formatMessage({id:"registrations.channel.other"});
    }
};

function RegistrationSummaryTable({reportRows, intl}){
    if (reportRows.length === 0) return null;

    return (
        <React.Fragment>
            <Table
                headerLabels={[
                    "reports.registrationsummary.channel",
                    "reports.registrationsummary.pending",
                    "reports.registrationsummary.active",
                    "reports.registrationsummary.deregister",
                    "reports.registrationsummary.deregistered",
                    "reports.registrationsummary.failed",
                    "reports.registrationsummary.total",
                ]}
                rows={reportRows.map(({channelPartnerSystemId, pending, active, deregister, deregistered, failed, total}) => {

                    return [
                        getChannel(intl, channelPartnerSystemId), pending, active, deregister, deregistered, failed, total
                    ];
                })}
                footer={
                    [" Total",
                        reportRows.reduce((a, {pending}) => a + pending, 0),
                        reportRows.reduce((a, {active}) => a + active, 0),
                        reportRows.reduce((a, {deregister}) => a + deregister, 0),
                        reportRows.reduce((a, {deregistered}) => a + deregistered, 0),
                        reportRows.reduce((a, {failed}) => a + failed, 0),
                        reportRows.reduce((a, {total}) => a + total, 0)
                    ]}
            >
            </Table>
            <CSVLink
                filename={`registration-summary-report-${new Date().getTime()}.csv`}
                data={reportRows}
                headers={[
                    {label: intl.formatMessage({id: "reports.registrationsummary.channel"}), key: "channelPartnerSystemId"},
                    {label: intl.formatMessage({id: "reports.registrationsummary.pending"}), key: "pending"},
                    {label: intl.formatMessage({id: "reports.registrationsummary.active"}), key: "active"},
                    {label: intl.formatMessage({id: "reports.registrationsummary.deregister"}), key: "deregister"},
                    {label: intl.formatMessage({id: "reports.registrationsummary.deregistered"}), key: "deregistered"},
                    {label: intl.formatMessage({id: "reports.registrationsummary.failed"}), key: "failed"},
                    {label: intl.formatMessage({id: "reports.registrationsummary.total"}), key: "total"}
                ]}>
                <SecondaryButton label="generic.download" icon="download"/>
            </CSVLink>
        </React.Fragment>
    )
};


export default injectIntl(RegistrationSummaryTable);

