import React from "react";

export const BlueTrim = () => (
    <div style={{
        backgroundColor: "#062A4B",
        height: "51px",
        width: "100%",
        position: "fixed",
        top: 0,
        zIndex: 1030,
    }}/>
);
