import React from "react";
import {FormattedDate, FormattedNumber, FormattedTime} from "react-intl";
import {CSVLink} from "react-csv";
import {Table, SecondaryButton, RegularText} from "../../common";
import {getTimeZone} from "../../../utils/date-utils";

const isAllSameCurrency = reportRows => {
    const allCurrencies = reportRows.map(({currencyCode}) => currencyCode);
    const uniqueCurrencies = new Set(allCurrencies);
    return uniqueCurrencies.size === 1
};

// todo: i18n the csv headers
const ReportTable = ({reportRows}) => {
    if (reportRows.length === 0) return <RegularText text="reports.billPayments.noResults"/>;
    return (
        <React.Fragment>
            <Table headerLabels={[
                "reports.billPayments.invoiceNumber",
                "reports.billPayments.invoiceAmount",
                "reports.billPayments.customerName",
                "reports.billPayments.paymentDate",
                "reports.billPayments.reference",
                "reports.billPayments.gatewayTxnId",
                "reports.billPayments.surcharge",
                "reports.billPayments.totalPaid"
            ]}
                   rows={reportRows.map(
                       ({invoiceNo, amountDue, customerName, paymentDate, reference, gatewayTxnId, surchargeAmount, totalInclSurcharge, currencyCode}) => {
                           const paymentDateObj = new Date(paymentDate);
                           return [
                               invoiceNo,
                               // eslint-disable-next-line
                               <FormattedNumber value={amountDue} style="currency" currency={currencyCode}/>,
                               customerName,
                               <span><FormattedDate value={paymentDateObj}/> <FormattedTime value={paymentDateObj}/></span>,
                               reference,
                               gatewayTxnId,
                               // eslint-disable-next-line
                               <FormattedNumber value={surchargeAmount} style="currency" currency={currencyCode}/>,
                               // eslint-disable-next-line
                               <FormattedNumber value={totalInclSurcharge} style="currency" currency={currencyCode}/>
                           ]
                       }
                   )}
                   footer={isAllSameCurrency(reportRows) && ["", "", "", "", "", "", "Total",
                       <FormattedNumber
                           value={reportRows.reduce((a, {totalInclSurcharge}) => a + totalInclSurcharge, 0)}
                           // eslint-disable-next-line
                           style="currency" currency={reportRows[0].currencyCode}/>]}
            >
            </Table>
            <CSVLink
                filename={`bill-payments-${new Date().getTime()}.csv`}
                data={reportRows.map(row => ({...row, paymentDate: new Date(row.paymentDate).toLocaleString("en-au", {timeZone: getTimeZone()})}))}
                headers={[
                    {label: "Invoice No", key: "invoiceNo"},
                    {label: "Invoice Amount", key: "amountDue"},
                    {label: "Customer Name", key: "customerName"},
                    {label: "Payment Date", key: "paymentDate"},
                    {label: "Reference", key: "reference"},
                    {label: "Gateway Txn ID", key: "gatewayTxnId"},
                    {label: "Surcharge", key: "surchargeAmount"},
                    {label: "Total Paid", key: "totalInclSurcharge"},
                ]}>
                <SecondaryButton label="generic.download" icon="download"/>
            </CSVLink>
        </React.Fragment>
    )
};

export default ReportTable;