import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Icon, LargeText, RegularText, SecondaryHeading, PrimaryButton} from "../../common";
import {CARD_ISSUER_LABELS, GATEWAY_DETAILS} from "../../payments/payment-constants";
import styles from "./GatewayDetails.module.scss";

const GatewayName = ({gateway}) => <b style={{marginRight: "0px"}}>{GATEWAY_DETAILS[gateway.type].label}</b>;

const CardDetails = ({acceptedCards}) => (
    <table className={styles.cards}>
        <thead>
        <tr>
            <th><RegularText className={styles.cardsHeading} text="settings.gateway.cardIssuer"/></th>
            <th><RegularText className={styles.cardsHeading} text="settings.gateway.surcharge"/></th>
        </tr>
        </thead>
        <tbody>
        {acceptedCards.map(card => (
            <tr key={card.issuer}>
                <td>{CARD_ISSUER_LABELS[card.issuer]}</td>
                <td>{card.surchargePercentage}%</td>
            </tr>
        ))}
        </tbody>
    </table>
);

const InActiveView = ({gateway}) => {
    const [isModalOpen, setIsModalOpen] = useState();
    return (
        <React.Fragment>
            <div className={styles.summaryLine}>
                <LargeText text="settings.gateway.configured"
                           values={{gatewayName: <GatewayName key={gateway.type} gateway={gateway}/>}}/>
            </div>
            <PrimaryButton label="settings.gateway.activate" onClick={() => setIsModalOpen(true)}/>
            <Modal show={isModalOpen}>
                <Modal.Body>
                    <LargeText text="settings.gateway.activate.instructions"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setIsModalOpen(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
};

const ActiveView = ({gateway}) => (
    <div className={styles.summaryLine}>
        <Icon name="ok" large/>
        <LargeText text="settings.gateway.activated"
                   values={{gatewayName: <GatewayName key={gateway.type} gateway={gateway}/>}}/>
    </div>
);

const GatewayDetails = ({gateway}) => {
    const GateWayStatusView = gateway.status === "active" ? ActiveView : InActiveView;
    return (
        <React.Fragment>
            <div className={styles.section}>
                <GateWayStatusView gateway={gateway}/>
            </div>

            <div className={styles.section}>
                <SecondaryHeading text="payments.accepted.cards.label"/>
                <CardDetails acceptedCards={gateway.invoicePaymentGatewayCard}/>
            </div>
            <div className={styles.section}>
                <PrimaryButton label="settings.gateway.createTransactionButton" linkTo={`./create-transaction`}/>
            </div>
        </React.Fragment>
    )
};

export default GatewayDetails;