import React, {useEffect, useState} from "react";
import {HtmlWidget} from '@paydock/client-sdk/lib/widget';
import {withRouter} from 'react-router-dom'
import axios from "axios";
import {Formik} from "formik";
import * as Yup from "yup";
import {injectIntl} from "react-intl";

import Loading from "../../Loading";
import InvoiceDetails from "../InvoiceDetails";
import {getInvoice} from "../payments-api";
import {
    Select,
    FormStep,
    PageHeading,
    Checkbox,
    LinkButton,
} from "../../common";
import SurchargeList from "../SurchargeList";

import styles from "./PaymentForm.module.scss"
import {useAppState} from "../../../state";
import {calculateSurcharge, setupExtraWigetFields, setWidgetDefaults} from "../../../utils/payment-utils";
import {CARD_ISSUER_LABELS} from "../payment-constants";
import {getCurrencySymbol} from "../../../utils/currency-utils";

//this is so the paydock iframe on complete callback has access to this var
var saveCardVar = false;
const initialiseSaveCardVar = () => {saveCardVar = false};

const onCardCaptureComplete = (invoiceId, amount, iframeResponse, cardIssuer, last4Digits, surchargePercentage, redirectPath, history) => {
    const isTokenForExpectedInvoice = iframeResponse.ref_id === invoiceId;
    if (isTokenForExpectedInvoice) {
        history.push({
            pathname: "./payment-confirmation",
            state: {iframeResponse, invoiceId, cardIssuer, last4Digits, surchargePercentage, amount: parseFloat(amount), saveCard: saveCardVar, redirectPath: redirectPath}
        })
    }
};

const onPayWithSavedCard = (selectedCard, invoiceId, amount, surchargePercentage, redirectPath, history) => {
    history.push({
        pathname: "./payment-confirmation",
        state: {cardId: selectedCard.id, invoiceId, cardIssuer: selectedCard.issuer, last4Digits: selectedCard.last4Digits, surchargePercentage, amount: parseFloat(amount), redirectPath: redirectPath}
    })
};

const configureCardDetailsWidget = (hasWidgetLoaded, setIsSubmitting, {publicKey, gatewayId, cardsAccepted, gatewayType, mode}
    , setCardIssuer, invoiceId, amount, redirectPath, history, user) => {
    let widget = new HtmlWidget("#widget", publicKey, gatewayId, 'card', 'payment_source');
    let cardIssuer = null;
    let last4Digits = null;
    setWidgetDefaults(
        widget,
        mode,
        invoiceId,
        cardsAccepted.map(ca => ca.issuer),
        "#paymentForm",
        () => hasWidgetLoaded(true),
        () => setIsSubmitting(true),
        () => setIsSubmitting(false),
        data => {
            setCardIssuer(data.card_scheme);
            cardIssuer = data.card_scheme;
            last4Digits = data.card_number_last4;
        },
        iframeResponse => onCardCaptureComplete(
            invoiceId,
            amount,
            iframeResponse,
            cardIssuer,
            last4Digits,
            cardsAccepted.find(ca => ca.issuer === cardIssuer).surchargePercentage,
            redirectPath,
            history
        ));
    setupExtraWigetFields(widget, gatewayType, user);
    widget.load();
};

const getGatewayDetails = (setGatewayDetails, invoiceId) => {
    axios.get(`/data/settings/invoice-payment-gateway-details/${invoiceId}`)
        .then(({data: {publicKey, gatewayId, cardsAccepted, gatewayType, mode}}) => {
            setGatewayDetails({publicKey, gatewayId, cardsAccepted, gatewayType, mode});
        });
};

export const SurchargeDetails = injectIntl(
    ({cardsAccepted, cardIssuer, selectedAmount, currencyCode, intl}) => {
        const issuer = cardsAccepted.find(ca => ca.issuer === cardIssuer);
        if (!issuer) {
            return null;
        }
        const surchargePercentage = issuer.surchargePercentage;
        const surchargeAmount = calculateSurcharge(surchargePercentage, selectedAmount);
        if (surchargeAmount === "0.00") return null;
        return (
            <div className={styles.surchargeContainer}>
                <p>{intl.formatMessage({id: "payments.paymentForm.surchargeNote"}, {
                    currencyCode: currencyCode.toUpperCase(),
                    currencySymbol: getCurrencySymbol(currencyCode),
                    surchargeAmount: surchargeAmount
                })}</p>
            </div>
        )
    });

const TXN_DETAILS = "TXN_DETAILS";
const CARD_DETAILS = "CARD_DETAILS";

const schema = Yup.object().shape({
    amount: Yup.string().required("forms.generic.required.label")
});

const handleTxnDetailsSubmit = (setStep, setSelectedAmount, values) => {
    setSelectedAmount(values.amount);
    setStep(CARD_DETAILS);
};

const getSelectOptions = (invoiceDetails, intl) => {
    const amountOption = {
        value: invoiceDetails.amountDue,
        label: intl.formatNumber(invoiceDetails.amountDue, {
            style: "currency",
            currency: invoiceDetails.currencyCode
        })
    };
    if (invoiceDetails.minAmountDue) {
        return [
            {
                value: invoiceDetails.minAmountDue,
                label: intl.formatNumber(invoiceDetails.minAmountDue, {
                    style: "currency",
                    currency: invoiceDetails.currencyCode
                })
            },
            amountOption]
    }
    return [amountOption]
};

const _EnterTransactionDetailsStep = ({invoiceDetails, step, setStep, setSelectedAmount, redirectPath, intl, history}) => {
    return (
        <FormStep title="settings.paymentForm.transactionDetailsStep.heading"
                  isOpen={true}
                  isActive={step === TXN_DETAILS}
                  className={styles.step}
        >
            <Formik
                initialValues={{
                    amount: "" + invoiceDetails.minAmountDue > 0 ? invoiceDetails.minAmountDue : invoiceDetails.amountDue,
                }}
                validationSchema={schema}
                onSubmit={values => handleTxnDetailsSubmit(setStep, setSelectedAmount, values)}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Select name="amount"
                                label="settings.paymentForm.amount.label"
                                placeholder={"payments.paymentForm.amount.placeholder"}
                                hint={step === TXN_DETAILS && "payments.paymentForm.amount.hint"}
                                options={getSelectOptions(invoiceDetails, intl)}
                                value={values.amount}
                                onChange={handleChange}
                                error={errors.amount}
                                touched={touched.amount}
                                disabled={isSubmitting || step !== TXN_DETAILS}
                                className={step !== TXN_DETAILS && styles.disabledAmount}
                                internationalisedOptions={false}
                        />

                        {step === TXN_DETAILS &&
                        <FormStep.Actions disabled={isSubmitting} onCancel={() => history.push(redirectPath)}/>}
                    </form>
                )}
            </Formik>
        </FormStep>
    );
};

const EnterTransactionDetailsStep = injectIntl(withRouter(_EnterTransactionDetailsStep))

const SelectSavedCard = ({invoiceDetails, savedCards, setUseDifferentCard, amount, cardsAccepted, redirectPath, history}) => (
    <Formik initialValues={{
        cardId: savedCards.length > 0 ? "" + savedCards[0].id : "",
    }}
            validationSchema={Yup.object().shape({
                cardId: Yup.string().required("payments.paymentForm.savedCardRequiredError")
            })}
            onSubmit={(values, {setSubmitting}) => {
                const cardId = parseInt(values.cardId);
                const selectedCard = savedCards.find(sc => sc.id === cardId);
                onPayWithSavedCard(selectedCard, invoiceDetails.id, amount,
                    cardsAccepted.find(ca => ca.issuer === selectedCard.issuer).surchargePercentage, redirectPath, history);
                setSubmitting(false);
            }}
    >
        {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting
          }) => {
            return (
                <form onSubmit={handleSubmit}>
                    {savedCards && (
                        <React.Fragment>
                            <Select name="cardId"
                                    placeholder="autoPayment.form.card.placeholder"
                                    internationalisedOptions={false}
                                    options={savedCards.map(c => ({
                                        value: c.id,
                                        label: CARD_ISSUER_LABELS[c.issuer] + " ***" + c.last4Digits + " " + c.cardHolderName
                                    }))}
                                    value={values.cardId}
                                    onChange={handleChange}
                                    error={errors.cardId}
                                    touched={touched.cardId}
                                    className={styles.cardSelect}
                            />
                            <LinkButton className={styles.useDifferentCardLink}
                                        label="payments.paymentForm.useDifferentCard"
                                        onClick={() => {
                                            setFieldValue("cardId", "");
                                            setUseDifferentCard(true);
                                        }}/>
                        </React.Fragment>
                    )}
                    <FormStep.Actions onCancel={() => history.goBack()} disabled={isSubmitting}/>
                </form>

            )
        }}
    </Formik>
);

const PaymentForm = ({match: {params: {invoiceId}}, location: {redirectOverride}, history}) => {

    const [invoiceDetails, setInvoiceDetails] = useState();
    useEffect(() => getInvoice(setInvoiceDetails, invoiceId), [setInvoiceDetails, invoiceId]);

    const [hasWidgetLoaded, setHasWidgetLoaded] = useState(false);
    const [isWidgetSubmitting, setIsWidgetSubmitting] = useState(false);
    const [gatewayDetails, setGatewayDetails] = useState();
    const [cardIssuer, setCardIssuer] = useState();
    const [useDifferentCard, setUseDifferentCard] = useState(false);

    //ALERT: Confusing code
    //this is required to get the save card toggle rerendering on change because it's a global var so the iframe response can read it.
    const [, setSaveCardRenderTrigger] = useState(false);
    useEffect(initialiseSaveCardVar, []);
    //END - ALERT: Confusing code

    const [{user}] = useAppState();

    useEffect(() => {
            getGatewayDetails(setGatewayDetails, invoiceId)
        },
        [setGatewayDetails, invoiceId]);

    const [savedCards, setSavedCards] = useState();
    useEffect(
        () => {
            if (invoiceDetails && invoiceDetails.payerActorId && gatewayDetails) {
                axios.get(`/data/cards/${invoiceDetails.payerActorId}`)
                    .then(({data}) => {
                        const useableCards = data.cards.filter(c => gatewayDetails.cardsAccepted.map(ca => ca.issuer).indexOf(c.issuer) !== -1)
                        setSavedCards(useableCards);
                        setUseDifferentCard(useableCards.length === 0);
                    });
            }
        },
        [invoiceDetails, setSavedCards, setUseDifferentCard, gatewayDetails]
    );

    const [step, setStep] = useState(TXN_DETAILS);
    const [selectedAmount, setSelectedAmount] = useState();

    const redirectDefault = invoiceDetails && `/portal/customer/biller/${invoiceDetails.payerActorId}/inbox/${invoiceDetails.id}`;
    const redirectPath = redirectOverride ? redirectOverride : redirectDefault;

    useEffect(() => {
            if (step === CARD_DETAILS && (useDifferentCard || (savedCards && savedCards.length === 0))) {
                configureCardDetailsWidget(setHasWidgetLoaded, setIsWidgetSubmitting, gatewayDetails, setCardIssuer, invoiceId, selectedAmount, redirectPath, history, user)
            }
        },
        [step, useDifferentCard, savedCards, setHasWidgetLoaded, setIsWidgetSubmitting, gatewayDetails, setCardIssuer, invoiceId, selectedAmount, redirectPath, history, user]);

    const isLoading = !invoiceDetails || !savedCards || !gatewayDetails;

    return (
        <div className={styles.paymentFormContainer}>
            {isLoading && <Loading/>}
            {!isLoading &&
            <div className={styles.paymentForm}>
                <div className={styles.header}>
                    <PageHeading text="payments.paymentForm.heading" values={{billerName: invoiceDetails.billerName}} showInWebview={true}/>
                    <InvoiceDetails details={invoiceDetails}/>
                </div>

                <EnterTransactionDetailsStep invoiceDetails={invoiceDetails} step={step} setStep={setStep}
                                             setSelectedAmount={setSelectedAmount} redirectPath={redirectPath}/>

                <FormStep title="settings.paymentForm.cardDetailsStep.heading" isOpen={step === CARD_DETAILS}
                          isActive={step === CARD_DETAILS}
                          className={styles.step}
                >
                    {gatewayDetails.cardsAccepted &&
                    <SurchargeList cardsAccepted={gatewayDetails.cardsAccepted}/>}

                    {!useDifferentCard && <SelectSavedCard invoiceDetails={invoiceDetails}
                                                           savedCards={savedCards}
                                                           setUseDifferentCard={setUseDifferentCard}
                                                           amount={selectedAmount}
                                                           cardsAccepted={gatewayDetails.cardsAccepted}
                                                           redirectPath={redirectPath}
                                                           history={history}/>}
                    {useDifferentCard && (
                        <form id="paymentForm">

                            {!hasWidgetLoaded && <Loading/>}

                            <div id="widget"
                                 className={styles.cardEntry}
                                 widget-style="background-color: #FFFFFF; button-color: rgba(27, 24, 80, 1); text-color: rgb(51, 51, 51); font-size: 15px;"/>
                            {cardIssuer &&
                            <SurchargeDetails cardsAccepted={gatewayDetails.cardsAccepted} cardIssuer={cardIssuer}
                                              selectedAmount={parseFloat(selectedAmount)} currencyCode={invoiceDetails.currencyCode}/>}

                            {hasWidgetLoaded &&
                            <React.Fragment>
                                <Checkbox name="saveCard"
                                          label="payments.paymentForm.saveCardCheckbox"
                                          value={saveCardVar}
                                          onChange={() => {
                                              setSaveCardRenderTrigger(sc => !sc);//required to trigger a re-render
                                              saveCardVar = !saveCardVar
                                              }}
                                />
                                <FormStep.Actions disabled={isWidgetSubmitting}
                                                  onCancel={() => history.push(redirectPath)}/>
                            </React.Fragment>}
                        </form>
                    )}

                </FormStep>
            </div>
            }
        </div>);
};

export default withRouter(PaymentForm);
