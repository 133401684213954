import {Alert, RegularText, MultipleFilesUpload, Modal, PrimaryButton, Select} from "../common";
import React, {useState, useCallback} from "react";
import axios from "axios";
import {Formik} from "formik";
import styles from "./RunJobModal.module.scss";
import {injectIntl} from "react-intl";
import * as Yup from "yup";
import {withRouter} from "react-router-dom";
import {ALERT_TYPES} from "../common/alerts/Alert";

const ErrorMessage = ({submitResult, filenames}) => (
    <Alert type={ALERT_TYPES.DANGER} className={styles.alertContainer}>
        <RegularText text={submitResult.message} />
    </Alert>
);

const submit = (billerId, uploadedFiles, {workflow}, submitResult, onSuccess, setIsSubmitting, setSubmitResult, history) => {
    setIsSubmitting(true);
    const data = {
        "file-ids": uploadedFiles.map(f => f.uuid),
        "workflow-id": workflow
    };

    axios.post(`/data/jobs/${billerId}/run`, data)
         .then(({data}) => {
             if(data.success) {
                 onSuccess();
                 history.push({pathname: `/portal/customer/biller/${billerId}/job/${data.jobId}`, state: {data: data}})
             } else {
                 setSubmitResult(data)
             }
             setIsSubmitting(false);
         })
         .finally(() => setIsSubmitting(false));
};

const schema = () => {
    return Yup.object().shape({
        workflow: Yup.string().required("forms.generic.required.label"),
    })
};

const RunJobModal = ({biller, workflowOptions, show, onCancel, history}) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitResult, setSubmitResult] = useState(null);

    const onSuccess = useCallback(() => {
        setUploadedFiles([]);
        onCancel();
    }, [onCancel]);

    const cancelAction = useCallback(() => {
        const data = {
            "file-ids": uploadedFiles.map(f => f.uuid),
        };
        axios.post(`/data/jobs/${biller.id}/dont-run`, data);
        setUploadedFiles([]);
        onCancel();
    }, [biller.id, uploadedFiles, onCancel]);

    if (!show) {
        return null;
    }

    return (
        <Formik
            initialValues={{workflow: ""}}
            validationSchema={schema}
            onSubmit={(values) => submit(biller.id, uploadedFiles, values, submitResult, onSuccess, setIsSubmitting, setSubmitResult, history)}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit
            }) => {

                const handleChangeSelect = e => {
                    handleChange(e);
                    setTimeout(() => e.target.blur(), 100);
                }

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal show={show}
                               title="job.workflow.header"
                               buttonLabel={submitResult && submitResult.confirmationRequired ? "job.workflow.submitWarningBtn" : "job.workflow.submitBtn"}
                               onCancel={cancelAction}
                               onPrimaryAction={handleSubmit}
                               PrimaryButtonComponent={PrimaryButton}
                               disabled={isSubmitting || !uploadedFiles || uploadedFiles.length === 0 || !uploadedFiles.every(f => f.status === 'done') || !values.workflow}
                               cancelDisabled={isSubmitting}
                        >
                            <MultipleFilesUpload name="files"
                                         label="job.workflow.addFiles"
                                         accept=".pdf,.xml,.csv,.zip,.gz,.tar.gz"
                                         className={styles.uploadField}
                                         error={errors.file}
                                         touched={touched.file}
                                         fileUploadURL={`/data/jobs/${biller.id}/upload-files`}
                                         uploadedFiles={uploadedFiles}
                                         setUploadedFiles={setUploadedFiles}
                                         setIsSubmitting={setIsSubmitting}
                                         disableAfterUpload={false}
                                         setSubmitResult={setSubmitResult}
                            />

                            <Select name="workflow"
                                    label="job.workflow.select"
                                    placeholder="job.workflow.select.placeholder"
                                    options={workflowOptions}
                                    value={values.workflow}
                                    onChange={handleChangeSelect}
                                    onBlur={handleBlur}
                                    error={errors.option}
                                    touched={touched.option}
                                    internationalisedOptions={false}
                            />
                            {submitResult && submitResult.message && <ErrorMessage submitResult={submitResult} />}
                        </Modal>
                    </form>
                )}}
        </Formik>
    );
};

export default withRouter(injectIntl(RunJobModal));
