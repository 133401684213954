export const CARD_ISSUER_LABELS = {
    visa: "Visa",
    mastercard: "Mastercard",
    amex: "American Express",
    diners: "Diners Club International",
    japcb: "Japanese Credit Bureau",
    laser: "Laser Deposits",
    solo: "Solo"
};

const buildUsernameFormInput = (label = "payments.gateways.generic.username") => ({name: "username", label: label});

const buildPasswordFormInput = (label = "payments.gateways.generic.password") => ({name: "password", label: label, type: "password"});

const buildMerchantFormInput = (label = "payments.gateways.generic.merchant") => ({name: "merchant", label: label});

const buildSignatureFormInput = (label = "payments.gateways.generic.signature") => ({name: "signature", label: label});

//keys should be camel case version of the Paydock API value. They will be translated in the backend by func get-paydock-gateway-type
export const GATEWAY_DETAILS = {
    advam: {
        label: "ADVAM",
        formInputs: [
            buildMerchantFormInput("Account Number"),
            buildUsernameFormInput("API Username"),
            buildPasswordFormInput("API Password")
        ]
    },
    promisepay: {
        label: "Assembly",
        formInputs: [
            buildUsernameFormInput(),
            buildPasswordFormInput("API Key")
        ]
    },
    authorize: {
        label: "Authorize Net",
        formInputs: [
            buildUsernameFormInput("API Login ID"),
            buildPasswordFormInput("Transaction Key")
        ]
    },
    ippayments: {
        label: "Bambora",
        formInputs: [buildMerchantFormInput(), buildUsernameFormInput(), buildPasswordFormInput()]
    },
    brain: {
        label: "Braintree",
        formInputs: [
            buildMerchantFormInput("The merchant account ID"),
            buildUsernameFormInput("payments.gateways.braintree.username"),
            buildPasswordFormInput("payments.gateways.braintree.password")
        ]
    },
    bpoint: {
        label: "Bpoint",
        formInputs: [
            buildMerchantFormInput("payments.gateways.bpoint.merchant"),
            buildUsernameFormInput("payments.gateways.bpoint.username"),
            buildPasswordFormInput("payments.gateways.bpoint.password"),
            {name: "meta.billerCode", label: "payments.gateways.bpoint.billerCode", optional: true}
        ]
    },
    eway: {
        label: "eWAY",
        formInputs: [
            buildUsernameFormInput("payments.gateways.eway.username"),
            buildPasswordFormInput("payments.gateways.eway.password")
        ]
    },
    ezydebit: {
        label: "Ezidebit",
        formInputs: [
            buildUsernameFormInput("Public Key"),
            buildPasswordFormInput("Digital Key")
        ]
    },
    ezydebit35: {
        label: "Ezidebit 3.5",
        formInputs: [
            buildUsernameFormInput("Public Key"),
            buildPasswordFormInput("Digital Key")
        ]
    },
    fatzebra: {
        label: "Fat Zebra",
        formInputs: [buildUsernameFormInput(), buildPasswordFormInput("Token")]
    },
    flow2cash: {
        label: "Flo 2 Cash",
        formInputs: [
            buildMerchantFormInput(),
            buildUsernameFormInput(),
            buildPasswordFormInput(),
            buildSignatureFormInput("Sub Account")
        ]
    },
    forte: {
        label: "Forte",
        formInputs: [buildMerchantFormInput(), buildMerchantFormInput(), buildPasswordFormInput(), buildSignatureFormInput()]
    },
    merchantwarrior: {
        label: "Merchant Warrior",
        formInputs: [
            buildMerchantFormInput("Merchant UUID"),
            buildUsernameFormInput("API Key"),
            buildPasswordFormInput("Passphrase")
        ]
    },
    nab: {
        label: "NAB",
        formInputs: [buildUsernameFormInput(), buildPasswordFormInput()]
    },
    pin: {
        label: "Pin",
        formInputs: [buildUsernameFormInput("Secret Key")],
        extraPaymentFields: ["email"]
    },
    paymentexpress: {
        label: "Payment Express",
        formInputs: [buildUsernameFormInput(), buildPasswordFormInput()]
    },
    securepay: {
        label: "Secure Pay",
        formInputs: [buildUsernameFormInput("Merchant"), buildPasswordFormInput()]
    },
    splitpayments: {
        label: "Split Payments",
        formInputs: [buildPasswordFormInput("Access Token")]
    },
    sqid: {
        label: "SQID",
        formInputs: [
            buildMerchantFormInput("Merchant Code"),
            buildUsernameFormInput("API Key"),
            buildPasswordFormInput("Passphrase")
        ]
    },
    stripe: {
        label: "Stripe",
        formInputs: [
            buildMerchantFormInput("payments.gateways.stripe.merchant"),
            buildUsernameFormInput("payments.gateways.stripe.password")
        ]
    },
    mastercard: {
        label: "MasterCard",
        formInputs: [
            buildUsernameFormInput(),
            buildPasswordFormInput()
        ]
    },
    payway: {
        label: "Westpac PayWay",
        formInputs: [
            buildMerchantFormInput(),
            buildUsernameFormInput("payments.gateways.westpac.username"),
            buildPasswordFormInput("payments.gateways.westpac.password")
        ]
    },
    worldpay: {
        label: "World Pay",
        formInputs: [
            buildUsernameFormInput("Client API Key"),
            buildPasswordFormInput("Service API Key")
        ]
    }
};