import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import axios from "axios";

import {FieldGroup, PrimaryButton, Number, LinkButton, PageHeading} from "../common";
import {getInvoice} from "./payments-api";

import {InvoiceAmount} from "./InvoiceDetails";
import Loading from "../Loading";
import {useAppState} from "../../state";

import styles from "./PaymentConfirmation.module.scss"
import {CARD_ISSUER_LABELS} from "./payment-constants";
import GenericError from "../GenericError";
import {calculateSurcharge} from "../../utils/payment-utils";

const payInvoice = (appState, paymentMethod, invoiceId, amount, cardIssuer, surchargePercentage,  redirectPath, history) => {
    axios.post(`/data/invoices/${invoiceId}/pay`, {paymentMethod, amount, cardIssuer, surchargePercentage}, {localErrorHandling: true})
        .then(({data}) => history.push({pathname: "./payment-result", state: {data, invoiceId, redirectPath}}))
        .catch(error => {
            history.push({pathname: "./payment-result", state: {data: {success: false}, redirectPath}})
        });
};

const NEW_CARD = "NEW_CARD";
const SAVED_CARD = "SAVED_CARD";

const ConfirmButton = withRouter(
    ({paymentMethod, amount, invoiceId, cardIssuer, surchargePercentage, redirectPath, history}) => {
        const [appState] = useAppState();
        const [isSubmitting, setIsSubmitting] = useState(false);
        return <PrimaryButton label="payments.pay.button.label"
                              onClick={() => {
                                  setIsSubmitting(true);
                                  payInvoice(appState, paymentMethod, invoiceId, amount, cardIssuer, surchargePercentage, redirectPath, history)
                              }}
                              isSubmitting={isSubmitting}/>
    });

const ConfirmationDetails = ({invoiceDetails, amount, cardIssuer, last4Digits, surchargePercentage}) => {
    const surchargeAmount = calculateSurcharge(surchargePercentage, amount);
    const total = surchargeAmount + amount;
    return (
        <FieldGroup className={styles.confirmationDetails} fields={[
            {label: "payments.confirmPayment.label.to", value: invoiceDetails.billerName},
            {label: "payments.confirmPayment.label.invoice", value: invoiceDetails.invoiceNo},
            {label: "payments.confirmPayment.label.card", value: `${CARD_ISSUER_LABELS[cardIssuer]} ${last4Digits}`},
            {
                label: "payments.confirmPayment.label.amount",
                value: <Number value={amount} type="currency" currency={invoiceDetails.currencyCode}/>
            },
            {label: "payments.confirmPayment.label.surcharge", value: <Number value={surchargeAmount} type="currency" currency={invoiceDetails.currencyCode}/>},
            {
                label: "payments.confirmPayment.label.total",
                value: <InvoiceAmount amount={total} currency={invoiceDetails.currencyCode}/>
            }
        ]}/>

    )};

const PaymentActions = ({paymentMethod, invoiceId, amount, cardIssuer, surchargePercentage, redirectPath, history}) => {
    return (
        <div className={styles.actions}>
            <LinkButton label="forms.generic.cancel.button"  onClick={() => history.push(redirectPath)}/>
            <ConfirmButton paymentMethod={paymentMethod} invoiceId={invoiceId} amount={amount} cardIssuer={cardIssuer} surchargePercentage={surchargePercentage} redirectPath={redirectPath}/>
        </div>
    )
};

export const PaymentConfirmationView = ({paymentMethod, amount, invoiceDetails, invoiceId, cardIssuer, last4Digits, surchargePercentage, redirectPath, history}) => (
    <div className={styles.container}>
        <div className={styles.detailsContainer}>
            <PageHeading className={styles.confirmationHeading} text="payments.confirmPayment.heading" showInWebview={true}/>
            <ConfirmationDetails invoiceDetails={invoiceDetails} amount={amount} cardIssuer={cardIssuer} last4Digits={last4Digits} surchargePercentage={surchargePercentage} />
            <PaymentActions paymentMethod={paymentMethod} invoiceId={invoiceId} amount={amount} cardIssuer={cardIssuer} surchargePercentage={surchargePercentage} redirectPath={redirectPath} history={history}/>
        </div>
    </div>
);

const PaymentConfirmation = ({location: {state: {cardId, iframeResponse, amount, invoiceId, cardIssuer, last4Digits, surchargePercentage, saveCard, redirectPath}}, history}) => {
    const [invoiceDetails, setInvoiceDetails] = useState();
    useEffect(() => getInvoice(setInvoiceDetails, invoiceId), [setInvoiceDetails, invoiceId]);

    if (!invoiceDetails) return <Loading/>;
    if (amount !== invoiceDetails.minAmountDue && amount !== invoiceDetails.amountDue) return <GenericError/>;

    return <PaymentConfirmationView paymentMethod={iframeResponse ? {method: NEW_CARD, oneTimeToken: iframeResponse.payment_source, saveCard} : {method: SAVED_CARD, cardId}}
                                    invoiceDetails={invoiceDetails}
                                    amount={amount}
                                    invoiceId={invoiceId}
                                    cardIssuer={cardIssuer}
                                    last4Digits={last4Digits}
                                    surchargePercentage={surchargePercentage}
                                    redirectPath={redirectPath}
                                    history={history}/>;
};

export default withRouter(PaymentConfirmation);
