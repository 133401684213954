export const SESSION_STORAGE_KEYS = {
    currentBillerId: 'currentBillerId', //value is integer
    mailSearchParams: 'mailSearchParams', //value is json stored as a string
};

export const resetSessionStateEmberToReact = (billerId) => {
    if(sessionStorage.getItem(SESSION_STORAGE_KEYS.currentBillerId) === billerId) {
        //re-apply session storage when the biller is still the same
        sessionStorage.setItem(SESSION_STORAGE_KEYS.mailSearchParams, sessionStorage.getItem(SESSION_STORAGE_KEYS.mailSearchParams));
    } else {
        //otherwise, reset the currentBillerId and session storage keys
        sessionStorage.setItem(SESSION_STORAGE_KEYS.currentBillerId, billerId);
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.mailSearchParams);
    }
}
