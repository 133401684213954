import React, {useEffect, useState} from "react";
import FastFormView from "./FastFormView";
import Loading from "../Loading";
import axios from "axios/index";

export const getBiller = (billerId, setBiller) => {
    const language = localStorage.getItem("language");
    axios.get(`/ff/biller/${billerId}/create`, {params: {language: language}})
        .then(({data}) => {
            setBiller(data.biller);
        });
};

const FastForm = ({billerId}) => {
    const [biller, setBiller] = useState();
    useEffect(
        () => getBiller(billerId, setBiller),
        [billerId, setBiller]
    );

    return biller ? <FastFormView billerId={billerId} biller={biller}/> : <Loading/>;
};

export default FastForm;
