import React, {useState} from "react";
import axios from "axios";

import {PageHeading, RegularText} from "../../common";
import Loading from "../../Loading";

import ReportTable from "./BillPaymentsReportTable";
import ReportWithDateRange from "../../ReportWithDateRange";
import {getTimeZone} from "../../../utils/date-utils";

const generateReport = (billerId, fromDate, toDate, setSubmitting, setReportRows) => {
    axios.post(`/data/reports/${billerId}/billpayments`, {fromDate, toDate, timeZone: getTimeZone()})
        .then(({data}) => setReportRows(data))
        .finally(() => setSubmitting(false))
    ;
};

const BillPaymentsReport = ({billerId}) => {
    const [reportRows, setReportRows] = useState();
    return (
        <div>
            <PageHeading text="reports.billPayments.heading"/>
            <RegularText text="reports.billPayments.description"/>
            <ReportWithDateRange generateReport={(fromDate, toDate, setSubmitting) => generateReport(billerId, fromDate, toDate, setSubmitting, setReportRows)}
            >
                {isSubmitting => (
                    <div>
                        {!isSubmitting && reportRows && <ReportTable reportRows={reportRows}/>}
                        {isSubmitting && <Loading/>}
                    </div>
                )}
            </ReportWithDateRange>
        </div>
    );
};

export default BillPaymentsReport;