export const SET_CONFIG = "SET_CONFIG";

export default (state, action) => {
    switch (action.type) {
        case SET_CONFIG:
            return action.config;

        default:
            return state;
    }
};