import React, {useState, useCallback} from "react";
import {injectIntl} from "react-intl";
import {ControlLabel, FormGroup, HelpBlock} from "react-bootstrap";
import styles from "./MultipleFilesUpload.module.scss";
import {RegularText, Table, LinkButton} from "../../index";
import axios from "axios";

const hasFileUploadStarted = (uploadProgress) => uploadProgress > 0;

const handleUploadChange = (e, fileUploadURL, uploadedFiles, setUploadedFiles, setProgress, setIsSubmitting, setSubmitResult) => {
    setIsSubmitting(true);
    const files = Array.from(e.target.files);
    if (files) {
        const pendingUploadedFiles = files.map(f => ({filename: f.name,
                                                      status: 'pending'}));
        setUploadedFiles([...uploadedFiles, ...pendingUploadedFiles]);
        const data = new FormData();
        files.forEach((file, id) => {
            data.append(`files-${id}`, file, file.name);
        });
        const noFiles = files.length;
        data.append("noFiles", noFiles);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                //only go to 95% and then set to 100% on response
                const percentageComplete = Math.max(0, Math.round((((progressEvent.loaded / progressEvent.total) * 100)) - 5));
                setProgress(percentageComplete);
            }
        }

        axios.post(fileUploadURL, data, config)
             .then(({data}) => {
                 if (data.success) {
                     const ids = data.ids;
                     const doneUploadedFiles = [...Array(noFiles).keys()].map(i => ({...pendingUploadedFiles[i],
                                                                                     uuid: ids[i],
                                                                                     status: 'done'}));
                     setUploadedFiles([...uploadedFiles, ...doneUploadedFiles]);
                     setProgress(100);
                     setIsSubmitting(false);
                 } else {
                     // if there was an issue, remove pending files and set progress to 0%
                     setSubmitResult(data);
                     setUploadedFiles(uploadedFiles.filter(f => (f.status === 'done')));
                     setProgress(0);

                 }
             })
             .finally(() => setIsSubmitting(false))

    }
};

const FilesTable = injectIntl(({intl, uploadedFiles, removeFile, removeAll}) => {
    return <Table>
               <thead>
                   <tr>
                       <td><ControlLabel>{intl.formatMessage({id: "job.workflow.files"})}</ControlLabel></td>
                       <td><LinkButton label="job.workflow.removeAllFiles" onClick={() => removeAll()}/></td>
                   </tr>
               </thead>
               <tbody>
                   {uploadedFiles.map((file, idx) => (
                       <tr key={idx}
                           className={styles.row}>
                           <td>{file.filename}</td>
                           <td><LinkButton label="job.workflow.removeFile" onClick={() => removeFile(file.uuid)}/></td>
                       </tr>
                   ))}
               </tbody>
           </Table>
});

const Index = ({name, label, accept = "", intl, fileUploadURL, uploadedFiles, setUploadedFiles, hint, className, setIsSubmitting, disableAfterUpload = true, setSubmitResult}) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [key, setKey] = useState(0);
    const clearInputUI = useCallback(() => {
        setUploadProgress(0);
        setKey(key + 1);
    }, [key]);
    const removeFileFunc = useCallback((uuid) => {
        if (uploadedFiles.length === 1) {
            clearInputUI();
        }
        setUploadedFiles(uploadedFiles.filter(f => f.uuid !== uuid))
    }, [uploadedFiles, setUploadedFiles, clearInputUI]);
    const removeAllFunc = useCallback(() => {
        setUploadedFiles([]);
        clearInputUI();
    }, [setUploadedFiles, clearInputUI]);
    return (
        <React.Fragment>
            <FormGroup className={className} controlId={name}>
                <ControlLabel>{intl.formatMessage({id: label})}</ControlLabel>
                {hint && <HelpBlock className={styles.helpBlock}>{intl.formatMessage({id: hint})}</HelpBlock>}
                <input type="file" accept={accept} onChange={e => handleUploadChange(e, fileUploadURL, uploadedFiles, setUploadedFiles, setUploadProgress, setIsSubmitting, setSubmitResult)}
                       disabled={hasFileUploadStarted(uploadProgress) && disableAfterUpload} multiple={true} className={styles.inputfile} key={key}
                />
                <div className={styles.progressBarWrapper}>
                    <progress id="progress-bar" className={styles.progressBar} value={uploadProgress} max="100"/>
                    <div className={styles.progressPercent}>
                        <RegularText text="mail.uploadMail.progress" className={styles.progressBarPercentText} values={{percentage: uploadProgress}} />
                    </div>
                </div>
            </FormGroup>

            {uploadedFiles && uploadedFiles.length > 0 &&
             <FilesTable uploadedFiles={uploadedFiles} removeFile={removeFileFunc} removeAll={removeAllFunc}/>}
        </React.Fragment>
    );
};

export default injectIntl(Index);
