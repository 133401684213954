import React from "react";
import {Link} from "react-router-dom";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

import {PageHeading, PrimaryButton} from "../common";
import styles from "./AddCard.module.scss";
import {buildMaskedNumber, expireMonth} from "../../utils/card-utils";

const CardsView = ({cards}) => {
    return (
        <React.Fragment>
            <PageHeading text="cards.listScreen.heading"/>

            <div className={styles.actionButtonsContainer}>
                <PrimaryButton label="cards.listScreen.addButtonLabel" icon="plus" linkTo="./cards/create"/>
            </div>

            <div className={styles.cardsContainer}>
                {cards.map(card => (
                    <div className={styles.cardPreview}>
                        <Link to={`./cards/${card.id}`}>
                            <Cards
                                cvc=""
                                expiry={`${expireMonth(card.expireMonth)}/${card.expireYear}`}
                                name={card.cardHolderName}
                                number={buildMaskedNumber(card.issuer, card.last4Digits)}
                                issuer={card.issuer}
                                preview={true}
                            />
                        </Link>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default CardsView;