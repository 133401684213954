import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import axios from "axios";

import {FieldGroup, PrimaryButton, Number, LinkButton, PageHeading} from "../../common";

import {useAppState} from "../../../state";

import styles from "./AdhocPaymentConfirmation.module.scss"
import {CARD_ISSUER_LABELS} from "../../payments/payment-constants";
import {calculateSurcharge} from "../../../utils/payment-utils";

const pay = (appState,oneTimeToken, amount, reference, cardIssuer, surchargePercentage, history) => {
    axios.post(`/data/settings/payment-gateway/${appState.biller.id}/pay`, {oneTimeToken, amount, reference, cardIssuer, surchargePercentage}, {localErrorHandling: true})
        .then(({data}) => history.push({pathname: "./payment-result", state: {data}}))
        .catch(error => {
            history.push({pathname: "./payment-result", state: {data: {success: false}}})
        });
};

const ConfirmButton = withRouter(
    ({oneTimeToken, amount, reference, cardIssuer, surchargePercentage, history}) => {
        const [appState] = useAppState();
        const [isSubmitting, setIsSubmitting] = useState(false);
        return <PrimaryButton label="payments.pay.button.label"
                              onClick={() => {
                                  setIsSubmitting(true);
                                  pay(appState, oneTimeToken, amount, reference, cardIssuer, surchargePercentage, history)
                              }}
                              disabled={isSubmitting}/>
    });

const ConfirmationDetails = ({amount, reference, cardIssuer, last4Digits, surchargePercentage, currency}) => {
    const surchargeAmount = calculateSurcharge(surchargePercentage, amount);
    const total = surchargeAmount + amount;
    return (
        <div className={styles.confirmationDetails}>
            <FieldGroup fields={[
                {label: "settings.paymentForm.reference.label", value: reference},
                {label: "forms.generic.card", value: `${CARD_ISSUER_LABELS[cardIssuer]} ${last4Digits}`},
                {label: "forms.generic.amount", value: <Number value={amount} type="currency" currency={currency}/>},
                {label: "settings.gateway.surcharge", value: <Number value={surchargeAmount} type="currency" currency={currency}/>},
                {label: "forms.generic.total", value: <Number value={total} type="currency" currency={currency}/>}
            ]}/>
        </div>
    )};

const PaymentActions = ({oneTimeToken, reference, amount, cardIssuer, surchargePercentage}) => {
    return (
        <div className={styles.actions}>
            <LinkButton label="forms.generic.cancel.button" linkTo={`./view`}/>
            <ConfirmButton oneTimeToken={oneTimeToken} cardIssuer={cardIssuer} reference={reference} amount={amount} surchargePercentage={surchargePercentage}/>
        </div>
    )
};

export const PaymentConfirmationView = ({oneTimeToken, reference, amount, cardIssuer, last4Digits, surchargePercentage, currency}) => (
    <div className={styles.container}>
        <div className={styles.detailsContainer}>
            <PageHeading className={styles.confirmationHeading} text="payments.confirmPayment.heading"/>
            <ConfirmationDetails reference={reference} amount={amount} cardIssuer={cardIssuer} last4Digits={last4Digits} surchargePercentage={surchargePercentage} currency={currency}/>
            <PaymentActions oneTimeToken={oneTimeToken} reference={reference} amount={amount} cardIssuer={cardIssuer} surchargePercentage={surchargePercentage}/>
        </div>
    </div>
);

const AdhocPaymentConfirmation = ({location: {state: {iframeResponse, amount, cardIssuer, last4Digits, surchargePercentage, currency}}}) => (
    <PaymentConfirmationView oneTimeToken={iframeResponse.payment_source}
                             reference={iframeResponse.ref_id}
                             amount={amount}
                             cardIssuer={cardIssuer}
                             last4Digits={last4Digits}
                             surchargePercentage={surchargePercentage}
                             currency={currency}/>
);

export default AdhocPaymentConfirmation;