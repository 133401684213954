export const MYOB_PRODUCTS = [
    {value: "3", label: "registrations.createMyob.connectProduct3"},
    {value: "1", label: "registrations.createMyob.connectProduct1"},
    {value: "2", label: "registrations.createMyob.connectProduct2"}];

export const TAX_OPTIONS = [
    {value: "Inclusive", label: "registrations.createMyob.tax.inclusive"},
    {value: "Exclusive", label: "registrations.createMyob.tax.exclusive"},
    {value: "NoTax", label: "registrations.createMyob.tax.notax"}];

export const AMOUNT_PAYABLE_OPTIONS = [
    {value: "TOTAL", label: "registrations.createMyob.amountPayable.total"},
    {value: "MINIMUM", label: "registrations.createMyob.amountPayable.minimum"}];
