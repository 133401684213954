import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {HtmlWidget} from "@paydock/client-sdk/widget";

import {LinkButton, PageHeading, SubmitButton} from "../common";
import PayDockCardDetails from "../PaydockCardDetails";
import {setWidgetDefaults} from "../../utils/payment-utils";
import Loading from "../Loading";
import styles from "./AddCard.module.scss"
import {CARD_ISSUER_LABELS} from "../payments/payment-constants";

const onCardCaptureComplete = (iframeResponse, setOneTimeToken, billerId) => {
    const isExpectedToken = parseInt(iframeResponse.ref_id) === billerId;
    if (isExpectedToken) {
        setOneTimeToken(iframeResponse.payment_source); //need to do this because can't do a post in the iframe onFinish callback
    }
};


const configureCardDetailsWidget = (billerId, hasWidgetLoaded, setIsSubmitting, setOneTimeToken) => {
    axios.get(`/data/settings/payment-gateway/${billerId}/create-card-config`)
        .then(({data: {publicKey, mode, cardEntryGatewayId}}) => {
            let widget = new HtmlWidget("#widget", publicKey, cardEntryGatewayId);
            setWidgetDefaults(
                widget,
                mode,
                billerId,
                Object.keys(CARD_ISSUER_LABELS),
                "#cardEntryForm",
                () => hasWidgetLoaded(true),
                () => setIsSubmitting(true),
                () => setIsSubmitting(false),
                null,
                iframeResponse => onCardCaptureComplete(
                    iframeResponse,
                    setOneTimeToken,
                    billerId
                ));
            widget.load();
        });
};

//need to do this because can't do a post in the iframe onFinish callback
const SavingCard = withRouter(({billerId, oneTimeToken, history, pathOnSuccess}) => {
    useEffect(() => {
            axios.post(
                `/data/cards/${billerId}`,
                {oneTimeToken})
                .then(({data}) => {
                    history.push({
                        pathname: pathOnSuccess ? pathOnSuccess.pathname : "./card-saved",
                        state: {data: data, ...pathOnSuccess ? pathOnSuccess.state : {}}
                    })
                })
        },
        [billerId, oneTimeToken, history, pathOnSuccess]);
    return <Loading text="cards.addCard.saving"/>
});

const AddCard = ({billerId, location}) => {
    const [hasWidgetLoaded, setHasWidgetLoaded] = useState(false);
    const [isWidgetSubmitting, setIsWidgetSubmitting] = useState(false);
    const [oneTimeToken, setOneTimeToken] = useState();

    useEffect(
        () => configureCardDetailsWidget(billerId, setHasWidgetLoaded, setIsWidgetSubmitting, setOneTimeToken),
        [billerId, setHasWidgetLoaded, setIsWidgetSubmitting, setOneTimeToken]);
    return (
        <div className={styles.container}>
            <PageHeading text="cards.listScreen.addButtonLabel" showInWebview={true}/>
            {!hasWidgetLoaded && <Loading/>}
            {hasWidgetLoaded && oneTimeToken && (
                <SavingCard billerId={billerId} oneTimeToken={oneTimeToken}
                            pathOnSuccess={location.state && location.state.pathOnSuccess ? {pathname: location.state.pathOnSuccess, state: location.state} : null}/>)}
            {!oneTimeToken &&
            <form id="cardEntryForm" className={styles.cardEntryForm}>

                <PayDockCardDetails id="widget"/>
                {hasWidgetLoaded && (
                    <div className={styles.actionButtonsContainer}>
                        <SubmitButton label="forms.generic.save.button"
                                      disabled={isWidgetSubmitting}/>
                        <LinkButton label="forms.generic.cancel.button"
                                    linkTo={location.state ? location.state.cancelPath : "../cards"}/>
                    </div>
                )}
            </form>
            }
        </div>
    );
};

export default withRouter(AddCard);
