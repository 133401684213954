import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import {injectIntl} from "react-intl";

import {PageHeading, LargeText, PrimaryButton, LinkButton} from "../common";

import styles from "./AddCard.module.scss"
import {buildMaskedNumber} from "../../utils/card-utils";

const CardSaved = ({location: {state: {data: {card}}}, billerId, intl}) => {
    return (
        <div className={styles.container}>
            <PageHeading text="cards.cardSaved.heading"/>
            <div className={styles.cardPreview}>
                <Cards
                    cvc=""
                    expiry={`${card.expireMonth}/${card.expireYear}`}
                    name={card.cardHolderName}
                    number={buildMaskedNumber(card.issuer, card.last4Digits)}
                    issuer={card.issuer}
                    preview={true}
                />
            </div>
            <LargeText text="cards.cardSaved.autoPaymentPrompt"/>
            <div className={styles.actionButtonsContainer}>
                <LinkButton label="cards.cardSaved.backButtonLabel" linkTo="../cards"/>
                <PrimaryButton label="cards.createAutoPayment.buttonLabel" linkTo="../auto-payments/create"/>
            </div>
        </div>
    );
};

export default injectIntl(CardSaved);